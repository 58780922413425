<template>
  <Header :hasTextTwo="true" textTwo="الخدمات"></Header>
  <div class="content px-20 my-10">
    <div
      class="search flex items-center border-2 w-fit pr-2 border-gray-200 rounded-xl"
    >
      <img class="w-5 ml-3" :src="search_2" alt="" />
      <input
        class="outline-none text-gray-500 py-2 pl-3"
        type="text"
        placeholder="ابحث باسم الخدمة"
      />
    </div>
    <div class="tabbing mt-10">
      <div class="tabs flex items-center space-x-2">
        <span
          name="all"
          @click="toggle($event)"
          :class="[
            { 'bg-primary text-white': states.all },
            { 'bg-white text-gray-500': !states.all },
            'tab font-medium border-2 border-gray-200 px-3 py-1 rounded-2xl hover:bg-primary hover:text-white cursor-pointer ml-2',
          ]"
          >الكل</span
        >
        <span
          name="companies"
          @click="toggle($event)"
          :class="[
            { 'bg-primary text-white': states.companies },
            { 'bg-white text-gray-500': !states.companies },
            'tab font-medium border-2 border-gray-200 px-3 py-1 rounded-2xl hover:bg-primary hover:text-white cursor-pointer ml-2',
          ]"
          >خدمات الشركات</span
        >
        <span
          name="law"
          @click="toggle($event)"
          :class="[
            { 'bg-primary text-white': states.law },
            { 'bg-white text-gray-500': !states.law },
            'tab font-medium border-2 border-gray-200 px-3 py-1 rounded-2xl hover:bg-primary hover:text-white cursor-pointer ml-2',
          ]"
          >الخدمات القانونية</span
        >
        <span
          name="consult"
          @click="toggle($event)"
          :class="[
            { 'bg-primary text-white': states.consult },
            { 'bg-white text-gray-500': !states.consult },
            'tab font-medium border-2 border-gray-200 px-3 py-1 rounded-2xl hover:bg-primary hover:text-white cursor-pointer ml-2',
          ]"
          >خدمات الاستشارات</span
        >
        <span
          name="individual"
          @click="toggle($event)"
          :class="[
            { 'bg-primary text-white': states.individual },
            { 'bg-white text-gray-500': !states.individual },
            'tab font-medium border-2 border-gray-200 px-3 py-1 rounded-2xl hover:bg-primary hover:text-white cursor-pointer ml-2',
          ]"
          >خدمات الافراد</span
        >
        <span
          name="general"
          @click="toggle($event)"
          :class="[
            { 'bg-primary text-white': states.general },
            { 'bg-white text-gray-500': !states.general },
            'tab font-medium border-2 border-gray-200 px-3 py-1 rounded-2xl hover:bg-primary hover:text-white cursor-pointer ml-2',
          ]"
          >خدمات عامة</span
        >
      </div>
      <p class="my-5 font-bold text-gray-600">
        عند تسجيل الدخول الي منصة اتمام للخدمات بامكانك اضافة خدماتك الالكترونية
        المفضلة وازالتها والتحكم بها
        <span class="text-primary cursor-pointer">من هنا</span>
      </p>
      <div class="content mt-10">
        <div v-if="states.all" class="all grid grid-cols-4 gap-5">
          <ServiceCard v-for="product in products.data" :id="product.id" :icon="generateDoc" :title='product.title' :desc='product.description'></ServiceCard>
        </div>
        <div v-if="states.companies" class="companies">خدمات الشركات هنا</div>
        <div v-if="states.law" class="law">خدمات الخدمات القانونية هنا</div>
        <div v-if="states.consult" class="consult">خدمات الاستشارات هنا</div>
        <div v-if="states.individual" class="individual">خدمات الافراد هنا</div>
        <div v-if="states.general" class="general">خدمات عامة هنا</div>
      </div>
    </div>
    <div class="pagination mt-16 mx-auto w-fit">
      <div class="flex items-center">
        <div
          class="hidden next ml-2 cursor-pointer w-12 h-12 flex justify-center items-center rounded-lg border-2 border-gray-200"
          @click="next"
        >
          <svg
            class="w-6 h-6"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"
              fill="gray"
            />
            <path d="M0 0h24v24H0z" fill="none" />
          </svg>
        </div>
        <div class="page flex items-center space-x-2">
          <span
              v-for="link in products.links"
              v-show="!isNaN(parseInt(link.label))"
              :class="{'bg-primary text-white':link.active}"
              @click="getProducts(link.label)"
            class="ml-2 w-12 h-12 flex justify-center items-center rounded-lg border-2 border-gray-200 text-lg font-medium pt-1 hover:bg-primary hover:text-white  cursor-pointer"
            >{{ link.label }}</span
          >
        </div>
        <div
          class=" hidden prev cursor-pointer w-12 h-12 flex justify-center items-center rounded-lg border-2 border-gray-200"
          @click="prev"
        >
          <svg
            class="w-6 h-6"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"
              fill="gray"
            />
            <path d="M0 0h24v24H0z" fill="none" />
          </svg>
        </div>
      </div>
    </div>
  </div>
  <Faq></Faq>
  <Contact></Contact>
  <Partners></Partners>
  <Footer></Footer>
</template>
<script setup>
import search_2 from "@/assets/icons/search_2.svg";
import generateDoc from "../assets/icons/generateDoc.svg";
import { ref, reactive, onMounted } from "vue";
import Header from "@/components/Header.vue";
import Partners from "@/components/Partners.vue";
import Contact from "@/components/Contact.vue";
import Faq from "@/components/Faq.vue";
import Footer from "@/components/Footer.vue";
import ServiceCard from "@/components/ServiceCard.vue";
import axios from "axios";

const products = ref([]);
function getProducts(page=1){
    axios.get("/products?page="+page).then((response) => {
        products.value = response.data;

    });
}
onMounted(() => {
    getProducts();
});
const states = reactive({
  all: true,
  companies: false,
  law: false,
  consult: false,
  individual: false,
  general: false,
});
function toggle(item) {
  for (let key in states) {
    states[key] = false;
  }
  states[item.target.getAttribute("name")] = true;
}

</script>
