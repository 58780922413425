<template>
  <Loader v-if="isLoading"></Loader>
    <component
          v-if="getPaymentReady"
        src="https://paylink.sa/assets/js/paylink.js"
        :is="'script'"
    ></component>
      <div class="flex items-center my-8 px-4">
        <img class="w-6" :src="homeIcon_2" alt="" />
        <span class="text-gray-500 font-medium text-lg mx-1"> الصفحة الرئيسية</span>
      </div>

  <div v-if="order&& order.status === 'Paid' " class="py-5 font-bold text-center text-3xl text-green-100 bg-green-500">
    شكرا لكم, لقد تم استلام الدفعة بنجاح
  </div>

<div v-if="order" class="main flex items-start sm:flex-row flex-col">
        <div class="order-details m-5 sm:w-2/3 w-full ">

          <div class="w-full bg-white justify-between items-center shadow flex">
            <div class="col-1">
               <div class="title text-2xl text-primary pt-5 pr-5 font-bold">{{ order.service.title }}</div>
             <div class="title text-sm text-primary pr-5 pb-5 font-medium	">{{ order.service.category.title }}</div>
            </div>
            <div class="status px-8 py-2 ">
                            <span v-if="order.status === 'new'" class="bg-green-50 font-bold px-5 py-2 text-lg text-green-400">
                  تم السداد
                </span>
                <span v-if="order.status === 'pending_payment'" class="bg-orange-50 font-bold px-5 py-2 text-lg text-orange-400">
                  بإنتظار السداد
                </span>
              <span v-if="order.status === 'preparing_quotation'" class="bg-orange-50 font-bold px-5 py-2 text-lg text-orange-400">
                  تحت المراجعة
                </span>
                            <span v-if="order.status === 'open'" class="bg-primary font-bold px-5 py-2 text-lg text-white">
                  جاري التنفيذ
                </span>
              <span v-if="order.status === 'done'" class="bg-green-50 font-bold px-5 py-2 text-lg text-green-400">
                  تم التنفيذ
                </span>
              <span v-if="order.status === 'rejected'" class="bg-red-50 font-bold px-5 py-2 text-lg text-red-400">
                  ملغي
                </span>
            </div>
          </div>

          <div class="w-full bg-white justify-between mt-5 pb-5 shadow flex flex-col">

            <div class="tabs outline-0 select-none flex justify-start pr-5 border-b border-gray-300 mb-5 w-full ">

              <div @click="changeTab('client_details')"
                   :class="{'text-primary':tabs.client_details, 'text-gray-400':!tabs.client_details}"
                    class="title text-lg cursor-pointer flex flex-col  pt-5 pl-5 font-bold">
                 <span>بيانات العميل</span>
                 <span :class="{hidden:!tabs.client_details}" class="h-1 mt-2 w-full bg-primary"></span>
               </div>

              <div @click="changeTab('service_details')"
                   :class="{'text-primary':tabs.service_details, 'text-gray-400':!tabs.service_details}"
                    class="title text-lg cursor-pointer flex flex-col  pt-5 pl-5 font-bold">
                 <span>بيانات الخدمة</span>
                 <span :class="{hidden:!tabs.service_details}" class="h-1 mt-2 w-full bg-primary"></span>
               </div>


                            <div @click="changeTab('service_documents')"
                   :class="{'text-primary':tabs.service_documents, 'text-gray-400':!tabs.service_documents}"
                    class="title text-lg cursor-pointer flex flex-col  pt-5 pl-5 font-bold">
                 <span>ملفات الخدمة</span>
                 <span :class="{hidden:!tabs.service_documents}" class="h-1 mt-2 w-full bg-primary"></span>
               </div>


                            <div @click="changeTab('payments_details')"
                   :class="{'text-primary':tabs.payments_details, 'text-gray-400':!tabs.payments_details}"
                    class="title text-lg cursor-pointer flex flex-col  pt-5 pl-5 font-bold">
                 <span>حالة الدفع</span>
                 <span :class="{hidden:!tabs.payments_details}" class="h-1 mt-2 w-full bg-primary"></span>
               </div>


            </div>

            <section v-if="tabs.client_details" class=" w-1/2">

              <div class="flex justify-between items-center mx-5 mt-5">
                 <span class="text-primary text-lg font-bold">اسم الشركة :</span>
                  <span class="text-gray-400 text-lg">{{ order.client.name }}</span>
              </div>

              <div class="flex justify-between items-center mx-5 mt-5">
                 <span class="text-primary text-lg font-bold">اسم مقدم الطلب :</span>
                  <span class="text-gray-400 text-lg">{{ order.contact.name }}</span>
              </div>
 <div class="flex justify-between items-center mx-5 mt-5">
                 <span class="text-primary text-lg font-bold">رقم الجوال :</span>
                  <span class="text-gray-400 text-lg">{{ order.contact.mobile }}</span>
              </div>

               <div class="flex justify-between items-center mx-5 mt-5">
                 <span class="text-primary text-lg font-bold">البريد الإلكتروني :</span>
                  <span class="text-gray-400 text-lg">{{ order.contact.email }}</span>
              </div>
            </section>
            <section v-if="tabs.service_details" class=" w-1/2">

              <div v-for="input in order.service.inputs" class="flex justify-between items-center mx-5 mt-5">
                 <span class="text-primary text-lg font-bold">{{ input.title }} :</span>
                  <span class="text-gray-400 text-lg">{{ input.value }}</span>
              </div>

            </section>

            <section v-if="tabs.payments_details" class="w-full">

              <div class="quotation-block" >
<template v-if="order.invoices">

                <div v-for="invoice in order.invoices" class="quotation-file bg-gray-100 mx-5 p-5 mt-3 flex justify-between items-center">
                    <div class="file flex items-center">
                      <img :src="bank_transfer" alt="">
                      <span class="mr-5 text-primary">فاتورة الطلب</span>
                    </div>
                    <div class="download p-2 bg-white shadow rounded hover:shadow cursor-pointer" >
                      <div v-if="invoice.status === 'Draft'" class=" font-bold text-orange-500">
                        بإنتظار تأكيدها من المالية
                      </div>
                        <div v-if="invoice.status === 'Rejected'" class=" font-bold text-red-500">
                        ملغية
                      </div>
                      <div v-if="invoice.status === 'Paid'" class=" font-bold text-red-500">
                        مدفوعة
                      </div>
                    </div>
                </div>

  <template v-for="quotation in order.quotations">
            <span class="text-primary font-bold mr-5 mb-5">قم بالاطلاع على ملف عرض السعر و اتمام السداد</span>

                  <div  class="quotation-file bg-gray-100 mx-5 p-5 mt-3 flex justify-between items-center">
                    <div class="file flex items-center">
                      <img :src="file" alt="">
                      <span class="mr-5 text-primary">ملف عرض السعر</span>
                    </div>
                    <div @click="downloadPDF(quotation.temp_reference)" class="download p-2 bg-white rounded hover:shadow cursor-pointer" >
                      <img :src="download" class="w-6" alt="">
                    </div>
                </div>
      <div v-if="isRejected" class="flex flex-col mx-5 mt-5">
                  <textarea name="comment" v-model="RejectComment" placeholder="اطلعنا على سبب رفضك..."
                            class="w-full h-56 rounded outline-0 font-bold pr-5 pt-5 bg-gray-100 border-0 ">
                  </textarea>
                  <div class="flex justify-between mt-5">
                     <div @click="isRejected=false" class="cursor-pointer  hover:shadow bg-gray-400 rounded  px-10 py-2">
                    <span class="text-white text-lg font-bold">رجوع</span>
                  </div>
                    <div @click="rejectQuotation(quotation.temp_reference)" class="cursor-pointer hover:shadow bg-primary rounded mr-5 px-10 py-2">
                    <span class="text-white text-lg font-bold">ارسال</span>
                  </div>
                  </div>
                </div>
                    <div v-if="!isRejected && !isPayment" class="flex mt-5 ml-5 items-center justify-end">
                  <div @click="isRejected=true" class="cursor-pointer hover:shadow bg-transparent border-2 border-primary rounded mr-5 px-10 py-2">
                    <span class="text-primary text-lg font-bold">رفض العرض</span>
                  </div>
                    <div class="cursor-pointer hover:shadow bg-primary rounded mr-5 px-10 py-2">
                    <span @click="acceptQuotation(quotation.temp_reference)" class="text-white text-lg font-bold">الموافقة</span>
                  </div>
                </div>
  </template>
      </template>


                <div v-if="isPayment" class="flex flex-col mx-5 mt-5">

                  <div class="payment-methods flex select-none">
                    <div  @click="changePaymentMethod('visa')" :class="{'border-primary': payment_methods.visa}" class="visa items-center cursor-pointer hover:shadow hover:border-primary flex border-2 justify-between p-3 w-full mx-2 rounded">
                      <div class="option w-6 h-6 border-2 rounded-full flex items-center justify-center" :class="{'border-primary': payment_methods.visa}">
                        <div :class="{'hidden': !payment_methods.visa}" class="rounded-full w-4 h-4 bg-primary"></div>
                      </div>
                      <img :src="visa" alt="">
                    </div>
                    <div  @click="changePaymentMethod('apple')" :class="{'border-primary': payment_methods.apple}" class="apple-pay items-center cursor-pointer hover:shadow hover:border-primary flex justify-between border-2 p-3 w-full mx-2 rounded">
                      <div class="option w-6 h-6 border-2 rounded-full flex items-center justify-center" :class="{'border-primary': payment_methods.apple}">
                          <div :class="{'hidden': !payment_methods.apple}" class="rounded-full w-4 h-4 bg-primary"></div>
                      </div>
                      <img :src="apple_pay" alt="">
                    </div>
                    <div @click="changePaymentMethod('mada')" :class="{'border-primary': payment_methods.mada}" class="mada items-center cursor-pointer hover:shadow hover:border-primary flex border-2 justify-between p-3 w-full mx-2 rounded">
                      <div class="option w-6 h-6 border-2 rounded-full flex items-center justify-center" :class="{'border-primary': payment_methods.mada}">
                          <div :class="{'hidden': !payment_methods.mada}" class="rounded-full w-4 h-4 bg-primary"></div>
                      </div>
                      <img :src="mada" alt="">
                    </div>
                    <div @click="changePaymentMethod('bank')"  :class="{'border-primary': payment_methods.bank}" class="bank-transfer items-center cursor-pointer hover:shadow hover:border-primary flex justify-between border-2 p-3 w-full mx-2 rounded">
                      <div class="option w-6 h-6 border-2 rounded-full flex items-center justify-center" :class="{'border-primary': payment_methods.bank}">
                          <div :class="{'hidden': !payment_methods.bank}" class="rounded-full w-4 h-4 bg-primary"></div>
                      </div>
                      <img :src="bank_transfer" alt="">
                    </div>
                  </div>

                  <template v-if="payment_methods.apple">
                                  <div class="mt-10 mb-5  shadow flex items-center bg-gray-100 mx-10 py-10 flex-col">
                  <img :src="coins" class="w-16" alt="">
                  <span class="font-bold mt-3">يجب التأكد من استخدام جهاز ايفون من متصفح سفاري لاتمام الدفع بشكل صحيح</span>
                </div>
                  </template>

                  <template v-if="payment_methods.visa || payment_methods.mada">
                    <span v-if="payment == null">تجهيز الدفع</span>
      <div class="flex items-center mt-5">
                                    <div class="flex flex-col w-full ml-5">
                    <span class="font-bold my-2">اسم صاحب البطاقة</span>
                    <input type="text" readonly id="card-name"  placeholder="ادخل اسم صاحب البطاقة" class="outline-0 focus:shadow py-3 pr-5 w-full bg-gray-100 text-primary font-bold">
                  </div>

                                    <div class="flex flex-col w-full ml-5">
                    <span class="font-bold my-2">رقم البطاقة</span>
                    <input type="text" readonly id="card-number"  placeholder="ادخل رقم البطاقة" class="outline-0 focus:shadow py-3 pr-5 w-full bg-gray-100 text-primary font-bold">
                  </div>
                      <div class="flex flex-col w-full  ml-5">
                    <span class="font-bold my-2">رمز التحقق CCV</span>
                    <input type="text" readonly id="card-ccv"  placeholder="ادخل رمز التحقق" class="outline-0 focus:shadow py-3 pr-5 w-full bg-gray-100 text-primary font-bold">
                  </div>
<div class="flex flex-col w-full">
                    <span class="font-bold my-2">تاريخ الإنتهاء</span>
                      <div class="expiry-date flex">
                        <input type="text" readonly id="card-month" placeholder="شهر" class="outline-0 text-center  py-3 pr-5 w-full bg-gray-100 text-primary font-bold">
                    <input type="text" readonly id="card-year" placeholder="سنة" class="outline-0 border-r-2 text-center py-3 pr-5 w-full bg-gray-100 text-primary font-bold">

                      </div>
                        </div>

                </div>

                    <div @click="toggleCheckbox" class="box select-none flex items-center mt-10 cursor-pointer  mr-5 ">
                      <div :class="{'bg-primary': cardDetails.acceptTerms, 'bg-gray-100':!cardDetails.acceptTerms}" class="border-2 border-primary   p-2 rounded hover:shadow">
                        <img  class="w-3 h-3  " :src="checkbox_mark" alt="">
                      </div>
                      <span class="text-lg text-gray-700 font-bold mr-5">
                        اقر بلاطلاع على <span class="text-primary">الشروط و الاحكام</span> و الموافقة عليها.
                      </span>
                    </div>
                  </template>
                <template v-if="payment_methods.bank">
                  <div class="flex items-center mt-5">

                    <div class="flex flex-col w-full ml-5">
                      <span class="font-bold my-2">اسم المحول</span>
                      <input type="text" v-model="bankDetails.name"  placeholder="ادخل اسم المحول" class="outline-0 focus:shadow py-3 pr-5 w-full bg-gray-100 text-primary font-bold">
                    </div>

                    <div class="flex flex-col w-full">
                     <span class="font-bold my-2">البنك المحول اليه</span>
                     <input type="text" v-model="bankDetails.bank" placeholder="ادخل اسم البنك" class="outline-0 focus:shadow py-3 pr-5 w-full bg-gray-100 text-primary font-bold">
                    </div>

                </div>

                  <div class="textarea mt-5 flex flex-col ">
                    <span class="font-bold my-2">ملاحظاتك</span>
                    <textarea name="comment" v-model="bankDetails.comment" placeholder="اضف ملاحظاتك..."
                              class="w-full h-56 rounded outline-0 font-bold pr-5 pt-5 bg-gray-100 border-0 ">
                    </textarea>
                  </div>
                  <div class=" input w-full  flex flex-col">
                       <span class="font-bold my-2">ايصال الحوالة</span>

                                      <UploadDocument>

                                      </UploadDocument>


                </div>
                  <div  class="hidden cursor-pointer hover:shadow-lg border-dashed border border-black flex items-center bg-gray-100 mt-10 p-10 items-center justify-center">
                    <img :src="upload" class="w-16" alt="">
                    <span class="font-bold mt-2 flex flex-col">
                      <span>ادرج ملفاتك</span>
                      <span>PDF , JPG, JPEG, PNG</span>
                    </span>
                  </div>

                </template>


                  <div class="flex justify-end mt-5">
                    <div @click="payNow" class="cursor-pointer hover:shadow bg-primary rounded mr-5 px-10 py-2">
                    <span class="text-white text-lg font-bold">تأكيد الدفع</span>
                  </div>

                  </div>

                </div>


              </div>

                <div v-if="order.status === 'new'" class="paid-message mt-5 shadow flex items-center bg-gray-100 mx-5 py-10 flex-col">
                  <img :src="done" class="w-16" alt="">
                  <span class="font-bold mt-2">تم بلفعل سداد الخدمة, شكرا لك !</span>
                </div>

              <div v-if="order.status === 'preparing_quotation' && order.quotations.length === 0" class="later-message shadow mt-5 flex items-center bg-gray-100 mx-5 py-10 flex-col">
                  <img :src="later" class="w-16" alt="">
                  <span class="font-bold mt-3">يؤجل الدفع لحين الانتهاء من دراسة الطلب و الموافقة عليه من قبل فريق اتمام</span>
                </div>
            </section>

          </div>

      </div>
      <div class="order-details pt-5 px-5 m-5 bg-white shadow pb-5  sm:w-1/3 w-full ">
         <div class="title text-xl  text-primary pt-5 pr-5 mb-2 font-medium	  ">تفاصيل الطلب</div>
        <hr class="border-2 mt-5 mx-5 ">


        <div class="flex justify-between items-center mx-5 mt-5">
            <span class="text-gray-400 text-lg">رقم الطلب</span>
            <span class="text-primary text-lg font-bold">{{ order.id }}</span>
        </div>

        <div class="flex justify-between items-center mx-5 mt-5">
            <span class="text-gray-400 text-lg">تصنيف الخدمة</span>
            <span class="text-primary text-lg font-bold">{{ order.service.category.title }}</span>
        </div>

        <div class="flex justify-between items-center mx-5 mt-5">
            <span class="text-gray-400 text-lg">اسم الخدمة</span>
            <span class="text-primary text-lg font-bold">{{ order.service.title }}</span>
        </div>

        <div class="flex justify-between items-center mx-5 mt-5">
            <span class="text-gray-400 text-lg">تاريخ تقديم الطلب</span>
            <span class="text-primary text-lg font-bold">{{ order.created_at }}</span>
        </div>

        <div class="flex justify-between items-center mx-5 mt-5">
            <span class="text-gray-400 text-lg">تكلفة الخدمة</span>
            <span class="text-primary text-lg font-bold">{{ order.cost }} ريال سعودي</span>
        </div>

      </div>
</div>


</template>


<script setup>



import homeIcon_2 from "@/assets/icons/home_2.svg";
import done from "@/assets/icons/done.svg";
import UploadDocument from "@/components/UploadDocument.vue";
import Loader from "@/components/Loader.vue";
import later from "@/assets/icons/later.svg";
import coins from "@/assets/icons/coins.svg";
import checkbox_mark from "@/assets/icons/checkbox_mark.svg";
import download from "@/assets/icons/download.svg";
import upload from "@/assets/icons/upload.svg";
import file from "@/assets/icons/file.svg";
import visa from "@/assets/icons/visa.svg";
import mada from "@/assets/icons/mada.svg";
import bank_transfer from "@/assets/icons/bank_transfer.svg";
import apple_pay from "@/assets/icons/apple_pay.svg";
import {ref, onMounted, inject} from "vue";
import axios from "axios";
import {useRoute} from "vue-router";
const route = useRoute();
const swal = inject("$swal");
const order = ref();
const transactionNo = ref(1711510225822);
const user = ref();
const isLoading = ref(true);
const token = ref(localStorage.getItem('token'));
 let payment = null;
const bankDetails = ref({
  name:'',
  bank:'',
  comment:''
})

const cardDetails = ref({
  acceptTerms:false,
  ccv:'',
  card_no:'',
  month:'',
  year:'',
})

function acceptQuotation(id){
        isLoading.value = true;
    axios
        .get("/update-quotation/" + id + "/" + 'true')
        .then((res) => {
            console.log(res.data);
            getOrder();
            isLoading.value = false;
            isPayment.value = true;
        })
        .catch((err) => {
            console.log(err.response.data.message);
            isLoading.value = false;
        });
}
function rejectQuotation(id){
        isLoading.value = true;
    axios
        .get("/update-quotation/" + id + "/" + 'false?comment=' + RejectComment.value)
        .then((res) => {
            console.log(res.data);
            getOrder();
            isLoading.value = false;
        })
        .catch((err) => {
            console.log(err.response.data.message);
            isLoading.value = false;
        });
}
function toggleCheckbox(){
  cardDetails.value.acceptTerms = !(cardDetails.value.acceptTerms);
}
const tabs = ref({
  client_details:false,
  service_details:false,
  service_documents:false,
  payments_details:true,
})

const payment_methods = ref({
  visa:false,
  mada:false,
  bank:true,
  apple:false,
})

const isRejected = ref(false);
const PaymentFullyLoaded = ref(false);
const getPaymentReady = ref(false);
const isPayment = ref(true);

const RejectComment = ref('');
function changeTab(tab){
  tabs.value.client_details = false;
  tabs.value.service_details = false;
  tabs.value.service_documents = false;
  tabs.value.payments_details = false;
  tabs.value[tab] = true;

}
function changePaymentMethod(method){
  payment_methods.value.visa = false;
  payment_methods.value.mada = false;
  payment_methods.value.apple = false;
  payment_methods.value.bank = false;
  payment_methods.value[method] = true;

  if(method === 'visa' || method === 'mada'){
    initializePaymentPaylink();
  }
}

function payBank(){
    axios.post("/pay-order-via-bank/"+route.params.id,bankDetails.value,{
          headers: {
      'Authorization': 'Bearer ' + token.value
    }
    }).then((response) => {
        order.value = response.data;
    }).finally(() => {

                order.value.service.inputs.forEach((value, index) => {
        if (order.value.service.inputs[index].id) {
          order.value.service.inputs[index].disabled = true;
          order.value.service.inputs[index].value =  getValueOfSavedInput(order.value.service.inputs[index].id);
        }
      });

      order.value.invoices.forEach((value, index) => {
        if (order.value.invoices[index].status === 'Draft' || order.value.invoices[index].status === 'Paid'){
          isPayment.value = false;
        }
      });
    })
        .catch(error => {
    // Handle error
      swal({
                                title: "خطأ",
                                text: error.response.data.message,
                                icon: "error",
                                iconColor: "#e53e3e",
                                confirmButtonColor: "#235578",
                                confirmButtonText: "حسنا",
      });

    }).finally(() => {

    });
}
function getOrder(){
    isLoading.value = true;

    axios.get("/order/"+route.params.id,{
      headers: {
        'Authorization': 'Bearer ' + token.value
      }
    }).then((response) => {
        order.value = response.data;
    }).finally(() => {
        isLoading.value = false;
        loadPaylinkScript();

        if (order.value.status === 'new'){
          isPayment.value = false;
        }

        if (order.value.status === 'preparing_quotation'){
          isPayment.value = false;
        }

        order.value.service.inputs.forEach((value, index) => {
          if (order.value.service.inputs[index].id) {
            order.value.service.inputs[index].disabled = true;
            order.value.service.inputs[index].value =  getValueOfSavedInput(order.value.service.inputs[index].id);
          }
        });

        order.value.invoices.forEach((value, index) => {
          if (order.value.invoices[index].status === 'Draft' || order.value.invoices[index].status === 'Paid'){
            isPayment.value = false;
          }
        });

    });

}
function getUser(){

  axios.get('client_user', {
    headers: {
      'Authorization': 'Bearer ' + token.value
    }
  })
  .then(response => {
    // Handle success
    getOrder();
    user.value = response.data;
  })
  .catch(error => {
    // Handle error
  });
}
const initPaymentPaylink = async () => {

                try {
                    await payment.initPayment('#card-number', '#card-name', '#card-year', '#card-month', '#card-ccv');

                } catch (error) {
                  console.log(error)
                }
};

                const payApplePay = async () => {
                    isLoading.value = true;
                    axios.get("/token/" + order.value.id,{
                          headers: {
                      'Authorization': 'Bearer ' + token.value
                    }
                    }).then((response) => {
                        transactionNo.value = response.data;
                         try {
                            let response = payment.submitApplePayInvoice(transactionNo.value);
                            console.log('response of send payment is:', response);
                           // alert('response is: ' + JSON.stringify(response));
                        } catch (error) {
                            console.log('calling send payment caused error: ', error);
                  isLoading.value = false;
                            alert(error.reason);
                        }
                    })

                };
                const payCredit = async () => {
                    isLoading.value = true;
                    axios.get("/token/" + order.value.id,{
                          headers: {
                      'Authorization': 'Bearer ' + token.value
                    }
                    }).then((response) => {
                        transactionNo.value = response.data;
                        try {
                            let response = payment.submitInvoice(transactionNo.value);
                            console.log('response of send payment is:', response);
                           // alert('response is: ' + JSON.stringify(response));
                        } catch (error) {
                            console.log('calling send payment caused error: ', error);
                              isLoading.value = false;
                            alert(error.reason);
                        }
                    })

                };
function loadPaylinkScript(){
  if (getPaymentReady.value === true){

  }else{
      getPaymentReady.value = true;
  }


}
function downloadPDF(id) {

     window.location.assign('https://etmaam-for-services.test/api/pdf/' + id);

}
function payNow(){

  if (payment_methods.value.bank === true){
      payBank();
  }else if(payment_methods.value.visa === true || payment_methods.value.mada){
      payCredit();
  }else if(payment_methods.value.apple === true){
      payApplePay();
  }

}

function initializePaymentPaylink(){


  if(PaymentFullyLoaded.value === false){

    payment = new PaylinkPayments({mode: 'test'});
    initPaymentPaylink();

  }


}
function getValueOfSavedInput(id){
  const returedVal = ref('');
  user.value.client.inputs.forEach((val, index) => {

    if(id === user.value.client.inputs[index].input_id){
    //  console.log(user.value.client.inputs[index].value)
      returedVal.value = user.value.client.inputs[index].value;
    }
  });
  return returedVal.value;
}

onMounted(() => {
  getUser();
})
</script>