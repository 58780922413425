<template>
    <div class="item flex flex-col">
          <p
            @click="open = !open"
            class="head hover:bg-primary hover:text-white flex justify-between items-center text-lg font-bold bg-gray-200 py-2 px-6 rounded-lg text-darkPrimary cursor-pointer"
          >
            {{ header }}<span class="text-3xl">+</span>
          </p>
          <div class="body mt-5 px-6" v-if="open">
            <p class="text-lg text-black font-medium">
              {{ body }}
            </p>
          </div>
        </div>
</template>
<script setup>
import { ref } from "vue";
const open = ref(false);
let props = defineProps({
    header: {
        type: String,
        default: 'ما هي شركة اتمام؟',
    },
    body: {
        type: String,
        default: 'لا تزال تحتاج الى خدمة الشركات لإتمام الخدمات التي تحتاجها، ولكن يمكنك الحصول عليها من خلال الخدمة التجارية الخاصة بنا.',
    },
})

</script>