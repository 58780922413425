<template>
  <Loader v-if="isLoading"></Loader>
      <div class="flex items-center my-8 px-4">
        <img class="w-6" :src="homeIcon_2" alt="" />
        <span class="text-gray-500 font-medium text-lg mx-1"> الصفحة الرئيسية</span>
      </div>

  <div class="stats flex sm:flex-row flex-col mx-5 " v-if="stats">

    <div class="stat-box flex bg-white justify-between items-center px-10 mx-2 w-full mb-2 sm:mb-0 sm:w-1/3 p-5">
      <div class="right flex flex-col">
        <span class="font-bold text-5xl text-primary">{{ stats['total'] }}</span>
        <span class="font-medium text-lg text-primary">اجمالي الطلبات</span>
      </div>
      <img :src="new_orders" alt="">
    </div>

        <div class="stat-box flex bg-white justify-between items-center px-10 mx-2  w-full sm:w-1/3 mb-2 sm:mb-0 p-5">
      <div class="right flex flex-col">
          <span class="font-bold text-5xl text-primary">{{ stats['preparing_quotation'] }}</span>
        <span class="font-medium text-lg text-primary">طلبات تحت الدراسة</span>
      </div>
      <img :src="under_study" alt="">
    </div>

        <div class="stat-box flex bg-white justify-between items-center px-10 mx-2  w-full sm:w-1/3 mb-2 sm:mb-0 p-5">
      <div class="right flex flex-col">
        <span class="font-bold text-5xl text-primary">{{ stats['pending_payment'] }}</span>
        <span class="font-medium text-lg text-primary">طلبات بانتظار السداد</span>
      </div>
      <img :src="waiting_payment" alt="">
    </div>

  </div>


    <div class="stats flex sm:flex-row flex-col mt-5 mx-5 " v-if="stats">

    <div class="stat-box flex bg-white justify-between items-center px-10 mx-2  w-full sm:w-1/3 mb-2 sm:mb-0 p-5">
      <div class="right flex flex-col">
        <span class="font-bold text-5xl text-primary">{{ stats['paid'] }}</span>
        <span class="font-medium text-lg text-primary">طلبات جاري العمل عليها</span>
      </div>
      <img :src="working_status" alt="">
    </div>

        <div class="stat-box flex bg-white justify-between items-center px-10 mx-2  w-full sm:w-1/3 mb-2 sm:mb-0 p-5">
      <div class="right flex flex-col">
        <span class="font-bold text-5xl text-primary">{{ stats['done'] }}</span>
        <span class="font-medium text-lg text-primary">طلبات منتهية</span>
      </div>
      <img :src="completed_status" alt="">
    </div>

        <div class="stat-box flex bg-white justify-between items-center px-10 mx-2  w-full sm:w-1/3 mb-2 sm:mb-0 p-5">
      <div class="right flex flex-col">
        <span class="font-bold text-5xl text-primary">{{ stats['rejected'] }}</span>
        <span class="font-medium text-lg text-primary">طلبات مرفوضة</span>
      </div>
      <img :src="rejected_status" alt="">
    </div>

  </div>



  <div class=" flex flex-col bg-white p-10 mt-8 mx-7" v-if="stats">
      <div class="title text-3xl font-bold mb-10 text-primary">
        الخدمات
      </div>

    <div class="flex items-center ">
      <div class="w-2/3">
        <ul class="flex justify-between items-center w-full p-5">
          <li class="text-primary ml-20">تصنيف الخدمة</li>
          <li class="text-primary">العدد</li>
          <li class="text-primary">النسبة</li>
        </ul>

    <ul class="flex justify-between items-start bg-gray-50  py-3 px-5 w-full">
        <li class="flex items-center text-primary">
          <span class="block w-5 h-5 border ml-2" style="background:#225476;"> </span>
          <span>طلبات جاري العمل عليها</span>
        </li>
        <li class="text-primary text-lg font-bold ml-7">{{ stats['paid'] }}</li>
        <li class="text-primary text-lg font-bold">{{ (stats['paid']/stats['total']*100).toFixed() }}%</li>
      </ul>
    <ul class="flex justify-between items-start mt-2 bg-gray-50  py-3 px-5 w-full">
        <li class="flex items-center text-primary">
          <span class="block w-5 h-5 border ml-2" style="background:#438CBD;"> </span>
          <span>طلبات تحت الدراسة</span>
        </li>
        <li class="text-primary text-lg font-bold mr-2">{{ stats['preparing_quotation'] }}</li>
        <li class="text-primary text-lg font-bold">{{ (stats['preparing_quotation']/stats['total']*100).toFixed() }}%</li>
      </ul>
    <ul class="flex justify-between items-start mt-2 bg-gray-50  py-3 px-5 w-full">
        <li class="flex items-center text-primary">
          <span class="block w-5 h-5 border ml-2" style="background:#BD7F20;"> </span>
          <span>طلبات بانتظار السداد</span>
        </li>
        <li class="text-primary text-lg font-bold">{{ stats['pending_payment'] }}</li>
        <li class="text-primary text-lg font-bold">{{ (stats['pending_payment']/stats['total']*100).toFixed() }}%</li>
      </ul>
    <ul class="flex justify-between items-start mt-2 bg-gray-50  py-3 px-5 w-full">
        <li class="flex items-center text-primary">
          <span class="block w-5 h-5 border ml-2" style="background:#55B983;"> </span>
          <span>طلبات منتهية</span>
        </li>
        <li class="text-primary text-lg font-bold mr-9">{{ stats['done'] }}</li>
        <li class="text-primary text-lg font-bold">{{ (stats['done']/stats['total']*100).toFixed() }}%</li>
      </ul>
    <ul class="flex justify-between items-start mt-2 bg-gray-50  py-3 px-5 w-full">
        <li class="flex items-center text-primary">
          <span class="block w-5 h-5 border ml-2" style="background:#8A5757;"> </span>
          <span>طلبات مرفوضة</span>
        </li>
        <li class="text-primary text-lg font-bold mr-6">{{ stats['rejected'] }}</li>
        <li class="text-primary text-lg font-bold">{{ (stats['rejected']/stats['total']*100).toFixed() }}%</li>
      </ul>

          <ul class="flex justify-between items-center w-full p-5">
        <li class="text-primary text-xl font-bold ml-32">الاجمالي</li>
        <li class="text-primary text-xl font-bold ml-6">{{ stats['total'] }}</li>
        <li class="text-primary text-xl font-bold">100%</li>
      </ul>
      </div>
      <div class="w-1/4 mr-20 items-center chart">
        <Doughnut
          id="my-chart-id"
          :options="chartOptions"
          :data="chartData"

        />
      </div>
    </div>

  </div>

    <div class=" flex flex-col bg-white p-10 mt-8 mx-7">
      <div class="flex items-center w-full justify-between">
       <span class="title text-3xl font-bold text-primary">طلباتي</span>
        <div class="options ml-5">
          <div class="status-filter flex px-8 py-4 bg-gray-100 hidden">
              <span class="text-primary">تصنيف حسب</span>
            <img class="w-3 h-3 mr-5" :src="arrowDown" alt="">
          </div>
           <div class="relative status-filter w-full mr-5 flex  ">
             <input @keyup.enter="getOrders()" v-model="search" type="text" placeholder="ابحث برقم الطلب..." class="bg-gray-100 block w-full outline-0 pl-20 pr-5 py-4 text-primary">
            <div class="bg-primary absolute left-2 top-0 bottom-0 m-auto w-10 h-10 rounded flex items-center justify-center">
               <img class="" :src="search_white" alt="">
            </div>
          </div>
        </div>
      </div>

      <div class="table mt-10">
        <ul class="flex bg-primary  justify-between items-center w-full p-5">
          <li class="text-white font-bold ">رقم الطلب</li>
          <li class="text-white font-bold">تصنيف الخدمة</li>
          <li class="text-white font-bold">اسم الخدمة</li>
          <li class="text-white font-bold">تاريخ الطلب</li>
          <li class="text-white font-bold">تكلفة الخدمة</li>
          <li class="text-white font-bold">الخيارات</li>
        </ul>

           <ul v-for="order in orders" class="flex group  bg-gray-100 hover:border-2 border-primary cursor-pointer  justify-between items-center w-full px-5 py-2 my-5">
          <li class="text-primary  ">{{ order.id }}</li>
          <li class="text-primary ">{{ order.service.category.title }}</li>
          <li class="text-primary ">{{ order.service.title }}</li>
          <li class="text-primary ">{{ order.created_at }}</li>
          <li class="text-primary ">{{ order.cost }} ريال </li>
          <li class="text-primary ">
           <router-link :to="'/view-order/'+order.id">
              <div class="button group-hover:bg-primary bg-transparent rounded cursor-pointer hover:shadow-xl flex  px-5 py-3">
              <img class="hidden group-hover:block " :src="details_white" alt="">
              <img class="group-hover:hidden " :src="details_primary" alt="">
              <span class="group-hover:text-white text-primary mr-2">التفاصيل</span>
            </div>
           </router-link>
          </li>
        </ul>

      </div>
  </div>
</template>

<script setup>
import homeIcon_2 from "@/assets/icons/home_2.svg";
import arrowDown from "@/assets/icons/arrowDown.svg";
import details_primary from "@/assets/icons/details_primary.svg";
import details_white from "@/assets/icons/details_white.svg";
import search_white from "@/assets/icons/search-white.svg";
import new_orders from "@/assets/icons/new_orders.svg";
import under_study from "@/assets/icons/under_study.svg";
import waiting_payment from "@/assets/icons/waiting_payment.svg";
import working_status from "@/assets/icons/working_status.svg";
import completed_status from "@/assets/icons/completed_status.svg";
import rejected_status from "@/assets/icons/rejected_status.svg";
import {ref, onMounted} from "vue";
import axios from "axios";
import Loader from "@/components/Loader.vue";
import { Doughnut } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement, CategoryScale, LinearScale } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale, LinearScale)
const chartData = ref({  labels: [

  ],
  datasets: [{
    label: 'الطلبات',
    data: [0, 50, 100,20,33],
    backgroundColor: [
      'rgb(34, 84, 118)',
      '#438CBD',
      '#55B983',
      '#8A5757',
      '#BD7F20'
    ],
    hoverOffset: 4
  }]
      });

      const chartOptions = ref({
        responsive: true
      });

const token = ref(localStorage.getItem('token'));
const orders = ref([]);
const search = ref('');
const stats = ref();
const isLoading = ref(true);

function getStat(){
   isLoading.value = true;
    axios.get('stats', {
    headers: {
      'Authorization': 'Bearer ' + token.value
    }

}).then(response => {
    // Handle success
    stats.value = response.data;
chartData.value = {  labels: [

  ],
  datasets: [{
    label: 'الطلبات',
    data: [parseInt(stats.value['paid']), parseInt(stats.value['preparing_quotation']), parseInt(stats.value['done']),parseInt(stats.value['rejected']),parseInt(stats.value['pending_payment'])],
    backgroundColor: [
      'rgb(34, 84, 118)',
      '#438CBD',
      '#55B983',
      '#8A5757',
      '#BD7F20'
    ],
    hoverOffset: 4
  }]
      };

  })
  .catch(error => {
    // Handle error
  }).finally(() => {
    isLoading.value = false;
  });
}
function getOrders(){
 isLoading.value = true;

  axios.get('orders?search='+search.value, {
    headers: {
      'Authorization': 'Bearer ' + token.value
    }

}).then(response => {
    // Handle success
    orders.value = response.data;
     isLoading.value = false;
  })
  .catch(error => {
    // Handle error
  });
}

onMounted(() => {
  getOrders();
  getStat();
})

</script>