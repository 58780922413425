<template>
    <div class="contact mt-20 mb-14 relative h-44 flex items-center">
    <img
      class="absolute top-0 left-0 w-full h-full object-cover -z-20"
      src="../assets/images/header.png"
      alt=""
    />
    <div
      class="overlay bg-darkPrimary absolute w-full h-full opacity-70 -z-10"
    ></div>
    <div
      class="content flex justify-between items-center w-full px-20 text-white font-medium"
    >
      <p class="text-2xl">تواصل معنا لمساعدتك في ادارة خدماتك</p>
      <span
        class="text-xl border-2 border-white px-6 py-2 rounded-sm hover:bg-white hover:text-black cursor-pointer"
        >تواصل معنا</span
      >
    </div>
  </div>
</template>
<script setup>

</script>