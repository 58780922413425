<template>
            <span
                @click="$refs.file.click()"
                              class="border hover:shadow py-2  pr-1 cursor-pointer text-lg focus:outline-none  rounded focus:border-primary"  >
                             <span v-if="!filename" class="bg-gray-200 text-gray-500 text-sm py-1 px-10 rounded">
                              ارفق ملف
                            </span>
               <span v-if="filename" class="bg-primary text-white text-sm py-1 px-10 rounded">
                              {{ filename }}
                            </span>
               <input type="file" ref="file" @change="chooseFile" class="hidden">
                    <div class="text-red-500 font-bold mt-2 text-justify">

                    </div>
                          </span>
</template>

<script setup>
import {ref} from "vue";

const filename = ref(null);
function chooseFile(e){
  filename.value = e.target.files[0].name;
}
</script>