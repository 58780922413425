import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Login from '../views/Login.vue'
import Service from '../views/Service.vue'
import Dashboard from '../views/Dashboard.vue'
import ServiceOrder from '../pages/ServiceOrder.vue'
import Register from "@/components/Register.vue";
import ViewOrder from "@/pages/ViewOrder.vue";
import Overview from "@/pages/Overview.vue";

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/dashboard',
    name: 'auth_dashboard',
    component: Dashboard,
    children: [
      {
        path: '/dashboard/service/:id',
        name: 'dashboard',
        component: ServiceOrder
      },
              {
        path: '/overview',
        name: 'overview',
        component: Overview
      },
      {
        path: '/view-order/:id',
        name: 'view-order',
        component: ViewOrder
      },
    ]
  },
              {
    path: '/service/:id',
    name: 'service',
    component: Service
  },

  {
    path: '/login',
    name: 'login',
    component: Login
  },
      {
    path: '/register',
    name: 'register',
    component: Register
  },


];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  data:{
    keyr:'1213',
  }
})

export default router
