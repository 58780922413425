<template>
      <section>
              <div class="flex items-end my-8 px-4">
        <img class="w-6" :src="homeIcon_2" alt="" />
        <span class="text-gray-500 font-medium text-lg">- خدمات الشركة</span>
      </div>

      <div
        class="content bg-white px-6 py-4 mx-auto rounded-lg"
        style="width: 96%"
      >
        <div
          class="head flex items-center justify-between pb-5 pt-3 border-b-2 border-gray-200"
        >
          <h2 v-if="product" class="text-xl font-bold text-primary">

          </h2>
          <div class="flex">
            <span
                @click="toggleDescMenu"
              class="text-lightPrimary py-1 px-4 font-medium border-2 border-lightPrimary rounded-full flex items-center cursor-pointer"
              ><img class="w-5 mb-1 ml-2" :src="question" alt="" /> تفاصيل
              الخدمة</span
            >
            <span
                @click="toggleFaqMenu"
              class="text-gray-400 bg-gray-50 py-1 px-4 font-medium border-2 border-gray-400 rounded-full mr-2 cursor-pointer"
              >الأسئلة الشائعة</span
            >
          </div>
        </div>
        <div class="multi-step__form mt-10">
          <div class="head w-full py-6">
            <div class="flex justify-center">
              <div class="w-1/3">
                <div class="relative mb-2">
                  <div @click="nextIcons(1)"
                    :class="['w-12 h-12 mx-auto rounded-full text-lg text-white flex items-center',{'bg-primary' : active == 1},{'bg-green-800' : steps.one}]"
                  >
                    <span class="text-center text-white w-full p-2 cursor-pointer">
                        <svg class="w-full" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="white" d="M9,10h1a1,1,0,0,0,0-2H9a1,1,0,0,0,0,2Zm0,2a1,1,0,0,0,0,2h6a1,1,0,0,0,0-2ZM20,8.94a1.31,1.31,0,0,0-.06-.27l0-.09a1.07,1.07,0,0,0-.19-.28h0l-6-6h0a1.07,1.07,0,0,0-.28-.19.32.32,0,0,0-.09,0A.88.88,0,0,0,13.05,2H7A3,3,0,0,0,4,5V19a3,3,0,0,0,3,3H17a3,3,0,0,0,3-3V9S20,9,20,8.94ZM14,5.41,16.59,8H15a1,1,0,0,1-1-1ZM18,19a1,1,0,0,1-1,1H7a1,1,0,0,1-1-1V5A1,1,0,0,1,7,4h5V7a3,3,0,0,0,3,3h3Zm-3-3H9a1,1,0,0,0,0,2h6a1,1,0,0,0,0-2Z"/></svg>
                    </span>
                  </div>
                </div>

                <div @click="nextIcons(1)" :class="['text-lg text-gray-500 font-medium mt-3 text-center md:text-base']">
                  بيانات العميل
                </div>
              </div>

              <div class="w-1/3" >
                <div class="relative mb-2">
                  <div
                    class="absolute left-full flex align-center items-center align-middle content-center"
                    style="
                      width: calc(100% - 2.5rem - 1rem);
                      top: 50%;
                      transform: translate(-50%, -50%);
                    "
                  >
                    <div
                      class="w-full rounded items-center align-middle align-center flex-1"
                    >
                      <div
                        :class="['w-0 rounded',{'bg-primary' : !disable || active == 2 || active == 3 },{'bg-green-800' : steps.one}, {'bg-gray-200' : disable || active == 1}]"
                        style="width: 100%;height: 6px;"
                      ></div>
                    </div>
                  </div>

                  <div @click="nextIcons(2)"
                    :class="['w-12 h-12 mx-auto bg-gray-200 rounded-full text-lg text-white flex items-center cursor-pointer', {'bg-primary' : !disable || active == 2 || active == 3 }, {'bg-gray-200' : disable || active == 1}]"
                  >
                    <span class="text-center text-gray-600 w-full p-2">
                        <svg class="w-full" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path fill="white" d="M21,2H7A3,3,0,0,0,4,5V23a3,3,0,0,0,3,3H8v1a3,3,0,0,0,3,3H21a1,1,0,0,0,.71-.29l6-6A1,1,0,0,0,28,23V9a3,3,0,0,0-3-3H24V5A3,3,0,0,0,21,2ZM11,6A3,3,0,0,0,8,9V24H7a1,1,0,0,1-1-1V5A1,1,0,0,1,7,4H21a1,1,0,0,1,1,1V6ZM24.59,24,22,26.6V25a1,1,0,0,1,1-1Z"/></svg>
                    </span>
                  </div>
                </div>

                <div class="text-gray-500 text-lg font-medium mt-3 text-center md:text-base">
                  بيانات وملفات الخدمة
                </div>
              </div>

              <div class="w-1/3">
                <div class="relative mb-2">
                  <div
                    class="absolute left-full flex align-center items-center align-middle content-center"
                    style="
                      width: calc(100% - 2.5rem - 1rem);
                      top: 50%;
                      transform: translate(-50%, -50%);
                    "
                  >
                    <div
                      class="w-full rounded items-center align-middle align-center flex-1"
                    >
                      <div
                        :class="['w-0 bg-gray-200 rounded', {'bg-primary' : active == 3}]"
                        style="width: 100%;height: 6px;"
                      ></div>
                    </div>
                  </div>

                  <div @click="nextIcons(3)"
                    :class="['w-12 h-12 mx-auto bg-gray-200 rounded-full text-lg text-white flex items-center cursor-pointer', {'bg-primary' : active == 3}]"
                  >
                    <span class="text-center text-gray-600 w-full p-2">
                      <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" x="0" y="0" version="1.1" viewBox="0 0 29 29" xml:space="preserve"><path fill="white" d="M14.5 27.065a12.465 12.465 0 0 1-8.839-3.655c-4.874-4.874-4.874-12.804 0-17.678 2.361-2.361 5.5-3.662 8.839-3.662s6.478 1.3 8.839 3.662c4.874 4.874 4.874 12.804 0 17.678a12.465 12.465 0 0 1-8.839 3.655zm0-22.995a10.43 10.43 0 0 0-7.425 3.076c-4.093 4.094-4.093 10.755 0 14.85 4.094 4.093 10.755 4.093 14.85 0 4.093-4.094 4.093-10.755 0-14.85A10.434 10.434 0 0 0 14.5 4.07zm8.132 18.633h.01-.01z"/><path fill="white" d="M13 19.369a.997.997 0 0 1-.707-.293L8.05 14.833a.999.999 0 1 1 1.414-1.414L13 16.955l6.538-6.538a.999.999 0 1 1 1.414 1.414l-7.245 7.245a.997.997 0 0 1-.707.293z"/></svg>
                    </span>
                  </div>
                </div>

                <div :class="['text-lg font-medium mt-3 text-center md:text-base', {'text-primary': active == 2 || active == 3}, {'text-gray-500' : active == 1 || active == 2}]">
                    تأكيد البيانات والدفع
                </div>
              </div>
            </div>
          </div>


          <div class="body mx-44">
            <div class="owner" v-if="active == 1">
                <div class="flex justify-evenly my-7">
                  <div class="input w-1/2 px-5 flex flex-col">

                  <label for="name" class="text-lg font-bold text-gray-500 mb-2">الاسم الكامل</label>
                  <input v-model="formData.name" type="text" disabled placeholder="ادخل اسم صاحب الطلب" class="border-2 py-2 pr-5 text-lg focus:outline-none  rounded focus:border-primary" name="name" >
                  <div class="text-red-500 font-bold mt-2 text-justify">
                    {{errors.name}}
                  </div>
                  </div>
               <div class="input w-1/2   px-5 flex flex-col">
                  <label for="name" class="text-lg font-bold text-gray-500 mb-2">رقم الهوية الوطنية - الإقامة</label>
                  <input v-model="formData.national_id" :disabled="isNational" type="text" placeholder="ادخل رقم الهوية الوطنية - الإقامة" class="border-2 py-2 pr-5 text-lg focus:outline-none  rounded focus:border-primary" name="name" >
                <div class="text-red-500 font-bold mt-2 text-justify">
                    {{errors.national_id}}
                  </div>
               </div>
                </div>
               <div class="flex justify-evenly my-7">
                  <div class="input w-1/2 px-5 flex flex-col">
                  <label for="name" class="text-lg font-bold text-gray-500 mb-2">رقم التواصل (واتساب)</label>
                  <input v-model="formData.mobile" disabled type="text" placeholder="ادخل رقم التواصل (واتساب)" class="border-2 py-2 pr-5 text-lg focus:outline-none  rounded focus:border-primary" name="name" >
                <div class="text-red-500 font-bold mt-2 text-justify">
                    {{errors.mobile}}
                  </div>
                  </div>
               <div class="input w-1/2 px-5 flex flex-col">
                  <label for="email" class="text-lg font-bold text-gray-500 mb-2">البريد الإلكتروني</label>
                  <input v-model="formData.email" type="email" disabled placeholder="ادخل البريد الإلكتروني" class="border-2 py-2 pr-5 text-lg focus:outline-none  rounded focus:border-primary" name="email" >
                <div class="text-red-500 font-bold mt-2 text-justify">
                    {{ errors.email }}
                  </div>
               </div>
                </div>
                <div class=" flex justify-start my-7">

                  <div class="hidden input w-1/2 px-5 flex flex-col">
                  <label for="name" class="text-lg font-bold text-gray-500 mb-2">
                    المدينة
                  </label>
                  <select
                      v-model="formData.city"
                          class=" block border py-2 pr-5 text-lg outline-0  text-gray-500   rounded " name="city" >
                    <option class="bg-gray-200 font-bold" disabled value="">يرجى اختيار المدينة</option>
                    <option value="1">الدمام</option>
                    <option value="2">الخبر</option>
                    <option value="3">الرياض</option>
                    <option value="4">جدة</option>
                  </select>
                    <div class="text-red-500 font-bold mt-2 text-justify">
                      {{errors.city}}
                    </div>
                </div>

                                    <div class=" hidden input w-1/2 px-5 flex flex-col">
                  <label for="name" class="text-lg font-bold text-gray-500 mb-2">
                    السجل التجاري
                  </label>

                                      <UploadDocument>

                                      </UploadDocument>


                </div>

                </div>

              <div class="flex justify-start ">
                     <div class="input w-1/2 px-5 flex flex-col">
                       <label for="name" class="text-lg font-bold text-gray-500 mb-2">
                      ملاحظات اضافية (اختياري)
                      </label>
                       <textarea v-model="formData.info" class="border-2 py-2 pr-5 text-lg focus:outline-none  rounded focus:border-primary" ></textarea>
                     </div>
              </div>

                <button @click="nextBtn" class="mt-6 block bg-primary text-white font-medium text-lg px-8 py-2 rounded-lg cursor-pointer">التالي</button>
            </div>
            <div class="service" v-if="active === 2">
              <div class="font-bold">
                بيانات الخدمة
              </div>
               <div class="flex justify-evenly my-7" v-if="product">
                  <div class="input w-1/2 px-5 flex flex-col" v-for="input in product.inputs">

                  <label for="name" class="text-lg font-bold text-gray-500 mb-2">{{ input.title }}</label>
                  <input v-if="input.type===0" :disabled="input.disabled" v-model="input.value" type="text" required :placeholder="input.placeholder" class="border-2 py-2 pr-5 text-lg focus:outline-none  rounded focus:border-primary" name="name" >

                  <select
                      v-if="input.type===1"
                      v-model="input.value"
                       :disabled="input.disabled"
                          class=" block border py-2 pr-5 text-lg outline-0  text-gray-500   rounded " name="city" >
                    <option class="bg-gray-200 font-bold" disabled value="">{{ input.placeholder }}</option>
                    <option
                        v-for="option in input.options.split('\n')"
                        :value="option.trim()"
                         :key="option.trim()"

                         :selected="option.trim() === input.value&&input.value.trim()">
                      {{ option }}
                    </option>
                  </select>
                  <div v-if="input&&input.error" class="text-red-500 font-bold mt-2">{{ input.error }}</div>
                    <div class="text-red-500 font-bold mt-2 text-justify">
                      {{errors.name}}
                    </div>
                  </div>

                </div>
                <div class="flex items-center mt-6">
                    <button @click="prevBtn" class="block bg-white border-2 border-gray-200 text-gray-500 font-medium px-5 py-2 rounded-lg cursor-pointer">السابق</button>
                    <button @click="nextBtn" class="mr-2 block bg-primary text-white font-medium text-lg px-8 py-2 rounded-lg cursor-pointer">التالي</button>
                </div>
            </div>
            <div class="ensure__payments" v-if="active === 3">
                  <span class="text-lg block font-bold mb-2">اختر طريقة الدفع</span>
                  <div v-if="true" class="paid-message shadow flex items-center bg-gray-100  py-10 flex-col">
                  <img :src="questionMark" class="w-16" alt="">
                  <span class="font-bold mt-2">يؤجل الدفع لحتى الإنتهاء من دراسة الطلب والموافقة عليه من قبل فريق اتمام</span>
                </div>
                <div class="flex items-center mt-6">
                    <button @click="prevBtn" class="block bg-white border-2 border-gray-200 text-gray-500 font-medium px-5 py-2 rounded-lg cursor-pointer">السابق</button>
                    <button @click="storeOrder" class="mr-2 block bg-primary text-white font-medium text-lg px-8 py-2 rounded-lg cursor-pointer">اتمام الطلب</button>
                </div>
            </div>
          </div>
        </div>
      </div>
      </section>
  <div v-if="descMenu || faqMenu || SuccessMenu" @click="closeAll" class="popup-wrapper bg-black opacity-50 w-full h-full fixed top-0 bottom-0 right-0 ">

  </div>
        <div v-if="faqMenu" class="popup overflow-y-scroll fixed  top-10   bg-white rounded right-0 shadow-lg  m-auto left-0 w-1/3 h-full">
    <div class="flex mt-5 items-center justify-center p-5">
      <img  @click="toggleFaqMenu" class="cursor-pointer absolute left-10 bg-gray-100 rounded-full p-3" :src="close" alt="">
      <div class="title text-2xl font-bold">الأسئلة الشائعة</div>
    </div>


            <div class="section-desc mt-5 bg-gray-100 items-center justify-between py-5 px-5 rounded mx-10 flex">
          <div class=" text-lg  text-primary items-center flex font-bold">
            <img class="h-5"  :src="questionMark" alt="">
            <span class="mr-3">ما انواع الأسئلة التي يجب تجنبها ؟</span>
          </div>
      <img  v-if="testFaq"  @click="testFaq=!(testFaq)" class="cursor-pointer  bg-white rounded-full p-3" :src="arrowUp" alt="">
      <img  v-if="!testFaq"  @click="testFaq=!(testFaq)" class="cursor-pointer  bg-white rounded-full p-3" :src="arrowDown" alt="">

        </div>
        <div v-if="testFaq" class="desc flex py-5 text-primary bold text-justify  mx-10   ">
          إذا كنت تحتاج إلى عدد أكبر من الفقرات يتيح لك مولد النص العربى زيادة عدد الفقرات كما تريد، النص لن يبدو مقسما ولا يحوي أخطاء لغوية، مولد النص العربى مفيد لمصممي المواقع على وجه الخصوص، حيث يحتاج
        </div>

            <div class="section-desc mt-5 bg-gray-100 items-center justify-between py-5 px-5 rounded mx-10 flex">
          <div class=" text-lg  text-primary items-center flex font-bold">
            <img class="h-5"  :src="questionMark" alt="">
            <span class="mr-3">ما انواع الأسئلة التي يجب تجنبها ؟</span>
          </div>
      <img  v-if="testFaq"  @click="testFaq=!(testFaq)" class="cursor-pointer  bg-white rounded-full p-3" :src="arrowUp" alt="">
      <img  v-if="!testFaq"  @click="testFaq=!(testFaq)" class="cursor-pointer  bg-white rounded-full p-3" :src="arrowDown" alt="">

        </div>
        <div v-if="testFaq" class="desc flex py-5 text-primary bold text-justify  mx-10   ">
          إذا كنت تحتاج إلى عدد أكبر من الفقرات يتيح لك مولد النص العربى زيادة عدد الفقرات كما تريد، النص لن يبدو مقسما ولا يحوي أخطاء لغوية، مولد النص العربى مفيد لمصممي المواقع على وجه الخصوص، حيث يحتاج
        </div>

                      <div class="section-desc mt-5 bg-gray-100 items-center justify-between py-5 px-5 rounded mx-10 flex">
          <div class=" text-lg  text-primary items-center flex font-bold">
            <img class="h-5"  :src="questionMark" alt="">
            <span class="mr-3">ما انواع الأسئلة التي يجب تجنبها ؟</span>
          </div>
      <img  v-if="testFaq"  @click="testFaq=!(testFaq)" class="cursor-pointer  bg-white rounded-full p-3" :src="arrowUp" alt="">
      <img  v-if="!testFaq"  @click="testFaq=!(testFaq)" class="cursor-pointer  bg-white rounded-full p-3" :src="arrowDown" alt="">

        </div>
        <div v-if="testFaq" class="desc flex py-5 text-primary bold text-justify  mx-10   ">
          إذا كنت تحتاج إلى عدد أكبر من الفقرات يتيح لك مولد النص العربى زيادة عدد الفقرات كما تريد، النص لن يبدو مقسما ولا يحوي أخطاء لغوية، مولد النص العربى مفيد لمصممي المواقع على وجه الخصوص، حيث يحتاج
        </div>
            <div class="section-desc mt-5 bg-gray-100 items-center justify-between py-5 px-5 rounded mx-10 flex">
          <div class=" text-lg  text-primary items-center flex font-bold">
            <img class="h-5"  :src="questionMark" alt="">
            <span class="mr-3">ما انواع الأسئلة التي يجب تجنبها ؟</span>
          </div>
      <img  v-if="testFaq"  @click="testFaq=!(testFaq)" class="cursor-pointer  bg-white rounded-full p-3" :src="arrowUp" alt="">
      <img  v-if="!testFaq"  @click="testFaq=!(testFaq)" class="cursor-pointer  bg-white rounded-full p-3" :src="arrowDown" alt="">

        </div>
        <div v-if="testFaq" class="desc flex py-5 text-primary bold text-justify  mx-10   ">
          إذا كنت تحتاج إلى عدد أكبر من الفقرات يتيح لك مولد النص العربى زيادة عدد الفقرات كما تريد، النص لن يبدو مقسما ولا يحوي أخطاء لغوية، مولد النص العربى مفيد لمصممي المواقع على وجه الخصوص، حيث يحتاج
        </div>
                      <div class="section-desc mt-5 bg-gray-100 items-center justify-between py-5 px-5 rounded mx-10 flex">
          <div class=" text-lg  text-primary items-center flex font-bold">
            <img class="h-5"  :src="questionMark" alt="">
            <span class="mr-3">ما انواع الأسئلة التي يجب تجنبها ؟</span>
          </div>
      <img  v-if="testFaq"  @click="testFaq=!(testFaq)" class="cursor-pointer  bg-white rounded-full p-3" :src="arrowUp" alt="">
      <img  v-if="!testFaq"  @click="testFaq=!(testFaq)" class="cursor-pointer  bg-white rounded-full p-3" :src="arrowDown" alt="">

        </div>
        <div v-if="testFaq" class="desc flex py-5 text-primary bold text-justify  mx-10   ">
          إذا كنت تحتاج إلى عدد أكبر من الفقرات يتيح لك مولد النص العربى زيادة عدد الفقرات كما تريد، النص لن يبدو مقسما ولا يحوي أخطاء لغوية، مولد النص العربى مفيد لمصممي المواقع على وجه الخصوص، حيث يحتاج
        </div>
                      <div class="section-desc mt-5 bg-gray-100 items-center justify-between py-5 px-5 rounded mx-10 flex">
          <div class=" text-lg  text-primary items-center flex font-bold">
            <img class="h-5"  :src="questionMark" alt="">
            <span class="mr-3">ما انواع الأسئلة التي يجب تجنبها ؟</span>
          </div>
      <img  v-if="testFaq"  @click="testFaq=!(testFaq)" class="cursor-pointer  bg-white rounded-full p-3" :src="arrowUp" alt="">
      <img  v-if="!testFaq"  @click="testFaq=!(testFaq)" class="cursor-pointer  bg-white rounded-full p-3" :src="arrowDown" alt="">

        </div>
        <div v-if="testFaq" class="desc flex py-5 text-primary bold text-justify  mx-10   ">
          إذا كنت تحتاج إلى عدد أكبر من الفقرات يتيح لك مولد النص العربى زيادة عدد الفقرات كما تريد، النص لن يبدو مقسما ولا يحوي أخطاء لغوية، مولد النص العربى مفيد لمصممي المواقع على وجه الخصوص، حيث يحتاج
        </div>
  </div>

      <div v-if="descMenu" class="popup fixed  top-10   bg-white rounded right-0 shadow-lg  m-auto left-0 w-1/3 h-full">
    <div class="flex mt-5 items-center justify-center p-5">
      <img  @click="toggleDescMenu" class="cursor-pointer absolute left-10 bg-gray-100 rounded-full p-3" :src="close" alt="">
      <div class="title text-2xl font-bold">تفاصيل الخدمة</div>
    </div>


            <div class="section-desc mt-5 bg-gray-100 items-center justify-between py-5 px-5 rounded mx-10 flex">
          <div class="text-lg text-primary items-center flex font-bold">
            <img class="h-8" :src="question" alt="">
            <span class="mr-3">وصف الخدمة</span>
          </div>
        </div>
        <div v-if="product" class="desc flex py-5 text-primary bold text-justify  mx-10   ">
          {{product.description}}
        </div>

                <div class="section-desc my-5 bg-gray-100 items-center justify-between py-5 px-5 rounded mx-10 flex">
          <div class="text-lg text-primary items-center flex font-bold">
            <img class="h-8" :src="time" alt="">
            <span class="mr-3">مدة الخدمة</span>
          </div>
          <div class="text-lg text-primary font-bold">
            <span>24 ساعة</span>
          </div>
        </div>

                <div class="section-desc my-5 bg-gray-100 items-center justify-between py-5 px-5 rounded mx-10 flex">
          <div class="text-lg text-primary items-center flex font-bold">
            <img class="h-8" :src="coins" alt="">
            <span class="mr-3">تكلفة الخدمة</span>
          </div>
          <div v-if="product" class="text-lg text-primary font-bold">
            <span>{{ parseInt(product.cost) === 0 ? 'بعد الدراسة' : product.cost + ' ريال ' }} </span>
          </div>
        </div>

           <div class="section-desc mt-5 bg-gray-100 items-center justify-between py-5 px-5 rounded mx-10 flex">
          <div class="text-lg text-primary items-center flex font-bold">
            <img  class="h-8" :src="question" alt="">
            <span class="mr-3">متطلبات الخدمة</span>
          </div>
        </div>
        <div class="desc flex flex-col pr-5 py-5 text-primary bold text-justify  mx-10   ">
          <p>1- الا يكون مقدم الطلب موظفا حكوميا أو احد الشركاء</p><p>2- أن يكون عمر مقدم الطلب والشركاء أكبر من ١٨ عاما</p><p>2- أن يكون مقدم الطلب والشركاء صبورين للغاية</p>
          </div>

  </div>
      <div v-if="SuccessMenu" class="popup fixed  top-10   bg-white rounded right-0 shadow-lg  m-auto left-0 w-1/3 ">
    <div class="flex mt-5 items-center justify-center p-5">
      <img  @click="toggleSuccessMenu" class="cursor-pointer absolute left-10 bg-gray-100 rounded-full p-3" :src="close" alt="">
    </div>

    <div class="flex flex-col justify-center items-center ">
          <img :src="accept" class="w-32 mb-5" alt="">
    <div class="title text-primary text-2xl font-bold text-center mb-2">
      تم ارسال الطلب بنجاح
    </div>
      <span class="text-gray-500 text-justify w-full px-36">
        سيتم مراجعة الطلب من قبل فريق إتمام وبناءاً على ذلك سوف يتم التواصل معكم
      </span>
    </div>

        <div class="flex items-center justify-center mt-10" v-if="message">
          <div class="flex flex-col items-center mx-10">
            <span class="text-gray-500 text-sm">رقم الطلب</span>
            <span class="text-xl">{{ message.id }}</span>
          </div>
          <div class="flex flex-col items-center mx-10">
            <span class="text-gray-500 text-sm">التاريخ</span>
            <span class="text-xl">{{message.created_at}}</span>
          </div>
        </div>


        <div class="flex items-center justify-around my-10">
          <div class="flex flex-col items-center mx-10">
          <router-link to="/overview">
                        <div class="bg-primary text-white p-2 px-5 rounded cursor-pointer hover:shadow">
              الرئيسية
            </div>
          </router-link>
          </div>
          <div class="flex flex-col items-center mx-10">
            <router-link :to="'/view-order/'+message.id">
            <div class="border-2 border-primary text-primary font-bold p-2 px-5 rounded cursor-pointer hover:shadow">
              عرض الطلب
            </div>
            </router-link>
          </div>

        </div>

  </div>
</template>

<script setup>

import {ref, onMounted} from "vue"
import axios from "axios"
import homeIcon_2 from "@/assets/icons/home_2.svg";
import close from "@/assets/icons/close.svg";
import done from "@/assets/icons/done.svg";
import time from "@/assets/icons/time.svg";
import coins from "@/assets/icons/coins.svg";
import accept from "@/assets/icons/accept.svg";
import question from "@/assets/icons/question.svg";
import arrowUp from "@/assets/icons/arrowUp.svg";
import arrowDown from "@/assets/icons/arrowDown.svg";
import questionMark from "@/assets/icons/questionMark.svg";
import UploadDocument from "@/components/UploadDocument.vue"
import {useRoute} from "vue-router";
import {useRouter} from "vue-router";

const token = ref(localStorage.getItem('token'));
const router = useRouter();
const route = useRoute();

const active = ref(1);
const user = ref();
const descMenu = ref(false);
const faqMenu = ref(false);
const SuccessMenu = ref(false);
const testFaq = ref(true);
const isNational = ref(false);
const disable = ref(true);
const message = ref();
const product = ref();

function toggleDescMenu(){
  descMenu.value = !(descMenu.value);
}
function toggleFaqMenu(){
  faqMenu.value = !(faqMenu.value);
}
function toggleSuccessMenu(){
  SuccessMenu.value = !(SuccessMenu.value);
}
function closeAll(){
  faqMenu.value = false;
  descMenu.value = false;
  SuccessMenu.value = false;
}
const formData = ref({
  name:'',
  email:'',
  national_id:'',
  mobile:'',
  city:'',
  info:'',
  documents:''

});
const errors = ref({
  name:'',
  email:'',
  national_id:'',
  mobile:'',
  city:'',

});


const steps = ref({
  one:false,
  two:false,
  three:false,
});
function nextIcons(val){
    if(disable.value === false){
        active.value = val;
    }
}
function getProduct(){
    axios.get("/product/"+route.params.id)
        .then((response) => {
      product.value = response.data;
        }).finally(() => {
      product.value.inputs.forEach((value, index) => {
        if (product.value.inputs[index].id) {
          product.value.inputs[index].disabled = (getValueOfSavedInput(product.value.inputs[index].id) === false) ? false : true;
          product.value.inputs[index].value =  (getValueOfSavedInput(product.value.inputs[index].id) === false) ? '' : getValueOfSavedInput(product.value.inputs[index].id);
        }
      });
    });
}
function storeOrder(){
  formData.value.service_id = product.value.id;
  formData.value.inputs = product.value.inputs;

    axios.post("/order", formData.value, {
      headers: {
        'Authorization': 'Bearer ' + token.value
      }
    }).then((response) => {
        message.value = response.data;
        SuccessMenu.value = true;
    });

}

function getValueOfSavedInput(id){
  const returedVal = ref(false);
  user.value.client.inputs.forEach((val, index) => {

    if(id === user.value.client.inputs[index].input_id){
    //  console.log(user.value.client.inputs[index].value)
      returedVal.value = user.value.client.inputs[index].value;
    }
  });
  return returedVal.value;
}
function checkStep2Validation(){
 let isError = false;
  product.value.inputs.forEach((value, index) => {
      if(value.value == null || value.value === "" || value.value === " "){
        product.value.inputs[index].error = "يرجى كتابة " + product.value.inputs[index].title;
         isError = true;
      }else{
        product.value.inputs[index].error = "";
      }


  })

  return isError;
}
function checkStep1Validation(){
  let inValid = false;
  errors.value.name = '';
  errors.value.mobile = '';
  errors.value.email  = '';
  errors.value.national_id = '';

  if(formData.value.name.length <=3){
    errors.value.name = 'يجب كتابة الاسم الكامل';
    inValid = true;
  }
    if(formData.value.national_id.length !== 10){
    errors.value.national_id = "رقم هوية أو اقامة غير صحيح";
    inValid = true;
  }

  if(formData.value.mobile.length <=3){
    errors.value.mobile = 'يرجى كتابة رقم الجوال';
    inValid = true;
  }
  if(formData.value.email.length <=3){
    errors.value.email = 'يجب كتابة البريد الالكتروني';
    inValid = true;
  }
 // if(formData.value.city === ''){
 //    errors.value.city = 'يجب اختيار المدينة';
 //    inValid = true;
 //  }
 return inValid;
}
function nextBtn() {

  if(active.value === 1){
     if(checkStep1Validation()){
      // return;
     }else{
       steps.value.one = true;
       active.value++;
     }
  }



      if(active.value === 2) {
        if (checkStep2Validation()) {

        } else {
          disable.value = false;
          active.value++;
        }

      }else{

      }


}
function prevBtn(){
    if (active.value === 1) {
        disable.value = false;
    } else {
        active.value--;
    }
}

function getUser(){

  axios.get('client_user', {
    headers: {
      'Authorization': 'Bearer ' + token.value
    }
  })
  .then(response => {
    // Handle success
    getProduct();
    user.value = response.data;
    formData.value.name = response.data.name;
    formData.value.email = response.data.email;
    formData.value.mobile = response.data.mobile;
    formData.value.national_id = (response.data.national_id) ? response.data.national_id : '';
    if(formData.value.national_id.length === 10){
      isNational.value = true;
    }
  })
  .catch(error => {
    // Handle error
  });
}

onMounted(() => {

  getUser();
})
</script>