
import { createApp } from 'vue'
import App from './App.vue'


import router from './router'
import store from './store'
import './index.css'

import axios from 'axios';

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

// // Production Base URL
// axios.defaults.baseURL = 'https://etmaam.co/api/shop/v1/';
// Development Base URL
axios.defaults.baseURL = 'https://etmaam.co/api/shop/v1/';

axios.defaults.withCredentials = true;

import VueSweetalert2 from "vue-sweetalert2";

createApp(App).use(store).use(router).use(VueSweetalert2).mount('#app')

