<template>
  <Header v-if="product"
    :hasTextTwo="true"
    textTwo="الخدمات"
    :hasTextThree="true"
   :text-three="product.title"
  ></Header>
  <div class="service__body mt-14 px-20 grid grid-cols-3 gap-x-5">
    <div class="service__details col-span-2 flex items-start">
      <img class="w-10" :src="question" alt="">
      <div class="main mr-5 mt-2">
        <div class="desc">
          <h1 class="text-lightPrimary font-bold text-xl">وصف الخدمة</h1>
          <p v-if="product" class="desc_body font-medium text-lg text-gray-500 mt-2">
            {{product.description}}
          </p>
        </div>
        <div class="coast_time mt-5 flex items-center justify-between">
            <div class="coast w-fit">
                <h1 class="text-lightPrimary font-bold text-lg flex items-center"><img class="w-8 ml-2" :src="coins" alt="">تكلفة الخدمة</h1>
                <p v-if="product" class="desc_body font-medium text-lg text-center text-gray-500 mt-2"> {{ parseInt(product.cost) === 0 ? 'بعد الدراسة' : product.cost + ' ريال ' }}</p>
            </div>
            <div class="coast w-fit">
                <h1 class="text-lightPrimary font-bold text-lg flex items-center"><img class="w-8 ml-2" :src="time" alt="">مدة تنفيذ الخدمة</h1>
                <p class="desc_body font-medium text-lg text-center text-gray-500 mt-2">24 ساعة</p>
            </div>
        </div>
        <div class="files mt-8">
            <div class="files w-fit">
                <h1 class="text-lightPrimary font-bold text-lg flex items-center"><img class="w-8 ml-2 fill-current text-primary" :src="documents" alt="">البيانات المطلوبة</h1>
                <div class="desc_body font-medium text-lg text-start mr-6 text-gray-500 mt-2">
                  <ol class="list-decimal" v-if="product">
                    <li v-for="info in product.inputs">{{ info.title }}</li>
                  </ol>
                </div>
            </div>
        </div>
        <div class="files mt-8">
            <div class="files w-fit">
                <h1 class="text-lightPrimary font-bold text-lg flex items-center"><img class="w-8 ml-2" :src="document" alt="">المتطلبات</h1>
                <div class="desc_body font-medium text-lg text-start mr-6 text-gray-500 mt-2">
                    <p>1- الا يكون مقدم الطلب موظفا حكوميا أو احد الشركاء</p>
                    <p>2- أن يكون عمر مقدم الطلب والشركاء أكبر من ١٨ عاما</p>
                    <p>2- أن يكون مقدم الطلب والشركاء صبورين للغاية</p>
                </div>
            </div>
        </div>
      </div>
    </div>
    <div class="start__service px-10">
        <div v-if="product"
            class="group flex flex-col items-center justify-center border-2 border-gray-200 rounded-lg py-14 relative overflow-hidden"
          >
            <img class="w-20" :src="generateDoc" alt="" />
            <p v-if="product" class="title mt-5 text-lg font-bold text-gray-600">
              {{ product.title }}
            </p>
  <router-link :to="{ name: 'dashboard', params: { id: product.id } }">
            <button class="flex hover:shadow-2xl border-2 items-center mt-8 bg-primary text-xl text-white font-medium px-6 py-3 cursor-pointer rounded-xl w-3/4 justify-center text-center">
                <img class="w-8 ml-2" :src="click" alt="">
                ابدأ الخدمة
            </button>
          </router-link>

          </div>
    </div>
  </div>
  <Faq></Faq>
  <Contact></Contact>
  <Partners></Partners>
  <Footer></Footer>
</template>
<script setup>
import Header from "@/components/Header.vue";
import Partners from "@/components/Partners.vue";
import Contact from "@/components/Contact.vue";
import Faq from "@/components/Faq.vue";
import Footer from "@/components/Footer.vue";
import question from "@/assets/icons/question.svg";
import coins from "@/assets/icons/coins.svg"
import time from "@/assets/icons/time.svg"
import documents from "@/assets/icons/documents.svg"
import document from "@/assets/icons/document.svg"
import generateDoc from "../assets/icons/generateDoc.svg";
import click from "@/assets/icons/click.svg";
import {onMounted, ref} from "vue";
import axios from 'axios';
import {useRoute} from "vue-router";
import {useRouter} from "vue-router";

const router = useRouter();
const route = useRoute();
const product = ref();
function getProduct(){
    axios.get("/product/"+route.params.id).then((response) => {
        product.value = response.data;

    });
}

onMounted(() => {
  getProduct();
})
</script>
