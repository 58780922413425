<template>
  <div class="flex h-screen">
    <div class="w-1/3 relative">
      <img
        class="w-full h-full object-cover"
        src="../assets/images/header.png"
        alt=""
      />
      <div
        class="overlay bg-darkPrimary absolute top-0 left-0 w-full h-full opacity-70 z-10"
      ></div>
      <div class="absolute top-0 z-30 left-0 w-full h-full p-10">
        <img class="w-44" src="../assets/logo.png" alt="" />
        <div class="mt-60 text-white">
          <div class="separator w-32 border border-b-4 bg-white mb-5"></div>
          <p class="text-4xl font-medium">
            اتمام للخدمات الأولي في المملكة في خدمات الأعمال
          </p>
          <p class="text-xl font-medium mt-7">
            يهدف عملنا إلى ابتكار الأفكار واستقطاب الكوادر التي تمثّل أعلى
            معايير التّميّز في تقديم خدمةٍ مهنيّةٍ احترافيّةٍ بأعلى جودةٍ ممكنةٍ
            وتمكين كلّ من يساهم بذلك من النّموّ المستمرّ
          </p>
        </div>
      </div>
    </div>
    <div class="w-2/3 flex items-center pr-28">
      <form action="">
        <h3 class="text-3xl font-bold text-primary">تسجيل الدخول</h3>
        <p class="mt-3 font-medium text-gray-500 text-sm">
          مرحبا بك مرة أخري في أشمل موقع للخدمات
        </p>
        <div class="form mt-8">
          <input
            type="email"
            v-model="loginData.email"
            required
            placeholder="البريد الالكتروني"
            class="w-full outline-none bg-transparent placeholder:text-gray-500 text-lg placeholder:font-medium border-2 py-3 px-2 rounded-md"
          />
          <div class="pass mt-5 relative">
            <input
              type="password"
              required
              v-model="loginData.password"
              ref="pass"
              placeholder="كلمة المرور"
              class="w-full outline-none bg-transparent placeholder:text-gray-500 text-lg placeholder:font-medium border-2 py-3 px-2 rounded-md"
            />
            <svg
                @click="$refs.pass.type==='text' ? $refs.pass.type='password':$refs.pass.type='text'"
              class="w-6 h-6 fill-current text-gray-500 hover:text-gray-700 absolute left-3 top-4 cursor-pointer"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <path d="M0 0h24v24H0z" fill="none" />
              <path
                d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z"
              />
            </svg>
          </div>
          <button
              type="button"
            @click="submitLogin"
            class="w-full bg-primary text-white font-medium py-3 px-4 rounded-md mt-5"
          >
            تسجيل الدخول
          </button>
          <div class="forget mt-6">
                 <span class="cursor-pointer hover:opacity-75 text-primary text-lg underline underline-offset-4">
              نسيت كلمة المرور؟
            </span>
            <p class="mt-2 mb-1 text-lg text-gray-500 font-medium">ليس لديك حساب في منصة اتمام للخدمات؟</p>
            <span class="cursor-pointer hover:opacity-75 text-primary text-lg underline underline-offset-4">
                انشاء حساب جديد
            </span>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script setup>

import {inject, onMounted, ref} from "vue";
import axios from "axios";
import {useRouter} from "vue-router";

const router = useRouter();
const swal = inject("$swal");

const loginData = ref({
  email:'',
password:''
});

function submitLogin(){
  console.log('called')
    axios.post('login',loginData.value)
      .then((res) => {

        if(res.data.status === true){
               localStorage.setItem('auth',true);
                localStorage.setItem('token',res.data.data.api_token);
                  router.push({name:'overview'});

        }else{
                            swal({
                                title: "خطأ",
                                text: res.data.message,
                                icon: "error",
                                iconColor: "#e53e3e",
                                confirmButtonColor: "#235578",
                                confirmButtonText: "حسنا",
                            });
        }
      });

}
onMounted(() => {

})

</script>
