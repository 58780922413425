<template>
  <section class="grid grid-cols-12 bg-gray-100 min-h-screen">
      <aside class="col-span-2 bg-primary flex flex-col">
      <header class="h-full">
        <img class="mt-5 mr-5 w-28 mb-20 " src="https://etmaam.com.sa/assets/front/img/619d47f38d315.png" alt="">
        <div class="menu">
          <router-link to="/overview">
                 <div class="menu-item flex items-center cursor-pointer px-10">
             <img class="w-6 fill-current	text-white ml-4 py-3" :src="homeIcon_1" alt="" />
            <span class="text-sm whitespace-nowrap text-white">الرئيسية</span>
          </div>
          </router-link>
          <div class=" flex flex-col cursor-pointer" v-for="category in categories">
            <div class="flex menu-item px-10 items-center">
                 <img class="w-6 fill-current	text-white ml-4 py-3" :src="homeIcon_1" alt="" />
            <span class="text-sm whitespace-nowrap text-white">{{ category.title }}</span>
            </div>
            <div  @click="goToProduct(productcat.id)" class="flex menu-item items-center justify-start py-3 px-10" v-for="productcat in category.products">
                <span class="w-1 h-1 rounded-full bg-white mx-4	text-white"></span>
            <span class="text-sm whitespace-nowrap text-white">{{ productcat.title }}</span>
            </div>
          </div>
        </div>
      </header>
    </aside>
    <main class="col-span-10">
   <header
        class="py-3 pr-4 pl-12 bg-white flex items-center justify-between"
      >
        <div class="search flex items-center bg-gray-100 w-fit pr-2 rounded-xl">
          <img class="w-5 ml-3" :src="search_2" alt="" />
          <input
            class="outline-none text-gray-500 bg-gray-100 py-3 pl-14 rounded-xl placeholder:font-medium"
            type="text"
            placeholder="ابحث باسم الخدمة"
          />
        </div>
        <div class="  relative flex flex-col user-menu">
          <div @click="userMenu=!(userMenu)" tabindex="-1" class="user shadow rounded px-6 py-2 flex w-full items-center cursor-pointer">
          <img class="w-10 ml-1" :src="user" alt="" />
          <span class="text-gray-500 font-bold" v-if="userDetails">{{userDetails.name}}</span>
          <svg
            class="w-6 h-6 rotate-90 mb-1"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"
              fill="gray"
            />
            <path d="M0 0h24v24H0z" fill="none" />
          </svg>

        </div>
          <div v-if="userMenu" @click="logout" class=" absolute bg-white left-0 right-0 m-auto justify-center py-5 top-12 flex items-center cursor-pointer">
            <span class="text-gray-500 whitespace-nowrap  font-bold" v-if="userDetails">تسجيل الخروج</span>
          </div>
        </div>
      </header>

    <router-view></router-view>

    </main>
  </section>
</template>
<script setup>
import search_2 from "@/assets/icons/search_2.svg";
import user from "@/assets/icons/user.svg";
import homeIcon_2 from "@/assets/icons/home_2.svg";
import homeIcon_1 from "@/assets/icons/home.svg";
import question from "@/assets/icons/question.svg";
import document from "@/assets/icons/document.svg"
import UploadDocument from "@/components/UploadDocument.vue"
import { ref, onMounted } from "vue";
import {useRoute} from "vue-router";
import {useRouter} from "vue-router";

const token = ref(localStorage.getItem('token'));
const router = useRouter();
const route = useRoute();
import axios from 'axios';


const active = ref(1);
const disable = ref(true);
const userMenu = ref(false);
const userDetails = ref();
const formData = ref({
  name:'',
  email:'',
  national_id:'',
  mobile:'',
  city:'',
  info:'',
  documents:''

});
const errors = ref({
  name:'',
  email:'',
  national_id:'',
  mobile:'',
  city:'',

});


const steps = ref({
  one:false,
  two:false,
  three:false,
});
function checkStep1Validation(){
  var inValid = false;
  errors.value.name = '';
  errors.value.mobile = '';
  errors.value.email  = '';
  errors.value.national_id = '';

  if(formData.value.name.length <=3){
    errors.value.name = 'يجب كتابة الاسم الكامل';
    inValid = true;
  }
    if(formData.value.national_id.length !== 10){
    errors.value.national_id = "رقم هوية أو اقامة غير صحيح";
    inValid = true;
  }

      if(formData.value.mobile.length <=3){
    errors.value.mobile = 'يرجى كتابة رقم الجوال';
    inValid = true;
  }
        if(formData.value.email.length <=3){
    errors.value.email = 'يجب كتابة البريد الالكتروني';
    inValid = true;
  }
 if(formData.value.city === ''){
    errors.value.city = 'يجب اختيار المدينة';
    inValid = true;
  }
 return inValid;
}
function nextBtn() {

  if(active.value == 1){
     if(checkStep1Validation()){
       return;
     }else{
       steps.value.one = true;
     }
  }



      if(active.value == 2) {
    disable.value = false;
    active.value++;
  } else {
    active.value++;
  }


}
function prevBtn(){
    if (active.value === 1) {
        disable.value = false;
    } else {
        active.value--;
    }
}
function nextIcons(val){
    if(disable.value === false){
        active.value = val;
    }
}

const categories = ref([]);
const product = ref();

function getProduct(){
    axios.get("/product/"+route.params.id).then((response) => {
        product.value = response.data;

    });
}
function goToProduct(id){
  console.log('clicked');
  window.location.assign('/dashboard/service/'+id);
}
function getCategories(){
    axios.get("/categories").then((response) => {
        categories.value = response.data;

    });
}

function getUser(){
                   axios.get('client_user', {
    headers: {
      'Authorization': 'Bearer ' + token.value
    }
  })
  .then(response => {
    // Handle success
    userDetails.value = response.data;
  })
  .catch(error => {
    // Handle error
  });
}

function logout(){

     localStorage.setItem('auth',false);
     localStorage.setItem('token',null);
     router.push('/login')
}

onMounted(() => {
  getUser();
  getCategories();

})
</script>
