<template>
    <div class="parteners px-20 pb-14">
    <h2 class="text-2xl font-bold">أكثر من ٢٠ شريك</h2>

    <div class="slider mt-5">
      <vueper-slides rtl :visible-slides="4" :gap="1" :slide-ratio="1 / 6">
        <vueper-slide
          v-for="(slide, i) in slides"
          :key="i"
          :image="slide.image"
        />
      </vueper-slides>
    </div>
  </div>
</template>
<script setup>
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";
import { ref } from "vue";
const slides = ref([
  {
    image: require("../assets/images/partners/dar.png"),
  },
  {
    image: require("../assets/images/partners/asn.png"),
  },
  {
    image: require("../assets/images/partners/ne.png"),
  },
  {
    image: require("../assets/images/partners/ora.png"),
  },
  {
    image: require("../assets/images/partners/pei.png"),
  },
  {
    image: require("../assets/images/partners/phar.png"),
  },
  {
    image: require("../assets/images/partners/real.png"),
  },
  {
    image: require("../assets/images/partners/sta.jpg"),
  },
]);
</script>